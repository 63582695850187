import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (!store.getters["user/user"]) {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "",
    component: () => import("@/layouts/MainLayout"),
    meta: { auth: true },
    children: [
      {
        path: "/",
        name: "PageHome",
        meta: {},
        component: () => import("@/views/page/PageHome"),
      },

      {
        path: "/handbook/category-breakdown/",
        name: "HandbookCategoryBreakdown",
        meta: {},
        component: () => import("@/views/handbook/CategoryBreakdown"),
      },
      {
        path: "/handbook/column/",
        name: "HandbookColumnList",
        meta: {},
        component: () => import("@/views/handbook/ColumnList"),
      },
      {
        path: "/handbook/object/",
        name: "HandbookObjectList",
        meta: {},
        component: () => import("@/views/handbook/ObjectList"),
      },
      {
        path: "/handbook/cost-center/",
        name: "HandbookСostСenter",
        meta: {},
        component: () => import("@/views/handbook/СostСenter"),
      },
      {
        path: "/handbook/project/",
        name: "HandbookProjectList",
        meta: {},
        component: () => import("@/views/handbook/ProjectList"),
      },

      {
        path: "/handbook-equipment/",
        name: "HandbookEquipmentList",
        meta: {},
        component: () => import("@/views/handbook-equipment/HandbookList"),
      },
      {
        path: "/handbook-equipment/category/",
        name: "HandbookEquipmentCategoryCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/category/CategoryCreate"),
      },
      {
        path: "/handbook-equipment/category/:categoryId/create/",
        name: "HandbookEquipmentSubCategoryCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/category/CategoryCreate"),
      },
      {
        path: "/handbook-equipment/category/:categoryId/brand/",
        name: "HandbookEquipmentCategoryBrandCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/category/CategoryBrandCreate"),
      },
      {
        path: "/handbook-equipment/category/:categoryId/parameter/",
        name: "HandbookEquipmentCategoryParameter",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/category/CategoryParameter"),
      },
      {
        path: "/handbook-equipment/brand/",
        name: "HandbookEquipmentBrandCreate",
        meta: {},
        component: () => import("@/views/handbook-equipment/brand/BrandCreate"),
      },
      {
        path: "/handbook-equipment/brand/:brandId/model/",
        name: "HandbookEquipmentBrandModelCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/brand/BrandModelCreate"),
      },
      {
        path: "/handbook-equipment/brand/:brandId/update/",
        name: "HandbookEquipmentBrandUpdate",
        meta: {},
        component: () => import("@/views/handbook-equipment/brand/BrandUpdate"),
      },
      {
        path: "/handbook-equipment/model/",
        name: "HandbookEquipmentModelCreate",
        meta: {},
        component: () => import("@/views/handbook-equipment/model/ModelCreate"),
      },
      {
        path: "/handbook-equipment/model/:modelId/",
        name: "HandbookEquipmentModelShow",
        meta: {},
        component: () => import("@/views/handbook-equipment/model/ModelShow"),
      },
      {
        path: "/handbook-equipment/model/:modelId/update/",
        name: "HandbookEquipmentModelUpdate",
        meta: {},
        component: () => import("@/views/handbook-equipment/model/ModelUpdate"),
      },
      {
        path: "/handbook-equipment/parameter/",
        name: "HandbookEquipmentParameterCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/parameter/ParameterCreate"),
      },
      {
        path: "/handbook-equipment/specification/",
        name: "HandbookEquipmentSpecificationCreate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/parameter/SpecificationCreate"),
      },
      {
        path: "/handbook-equipment/category/:categoryId/update/",
        name: "HandbookEquipmentCategoryUpdate",
        meta: {},
        component: () =>
          import("@/views/handbook-equipment/category/CategoryUpdate"),
      },

      {
        path: "/catalog/constructor/",
        name: "CatalogConstructor",
        meta: {},
        component: () => import("@/views/catalog/CatalogConstructor"),
      },

      {
        path: "/catalog/:catalogId/technic",
        name: "CatalogTechnicList",
        meta: {},
        component: () => import("@/views/catalog/technic/TechnicList"),
      },
      {
        path: "/catalog/:catalogId/technic/create",
        name: "CatalogTechnicCreate",
        meta: {},
        component: () => import("@/views/catalog/technic/TechnicCreate"),
      },
      {
        path: "/catalog/:catalogId/technic/:technicId",
        name: "CatalogTechnicShow",
        meta: {},
        component: () => import("@/views/catalog/technic/TechnicShow"),
      },
      {
        path: "/catalog/:catalogId/technic/:technicId/update",
        name: "CatalogTechnicUpdate",
        meta: {},
        component: () => import("@/views/catalog/technic/TechnicUpdate"),
      },

      {
        path: "/employee/",
        name: "EmployeeList",
        meta: {},
        component: () => import("@/views/employee/EmployeeList"),
      },
      {
        path: "/employee/create",
        name: "EmployeeCreate",
        meta: {},
        component: () => import("@/views/employee/EmployeeCreate"),
      },
      {
        path: "/employee/:id/update",
        name: "EmployeeUpdate",
        meta: {},
        component: () => import("@/views/employee/EmployeeUpdate"),
      },

      {
        path: "/company/",
        name: "CompanyList",
        meta: {},
        component: () => import("@/views/company/CompanyList"),
      },
      {
        path: "/department/",
        name: "DepartmentList",
        meta: {},
        component: () => import("@/views/department/DepartmentList"),
      },
      {
        path: "/position/",
        name: "PositionList",
        meta: {},
        component: () => import("@/views/position/PositionList"),
      },
      {
        path: "/role/",
        name: "RoleList",
        meta: {},
        component: () => import("@/views/role/RoleList"),
      },

      {
        path: "/equipment/",
        name: "EquipmentList",
        meta: {},
        component: () => import("@/views/equipment/EquipmentList"),
      },
      {
        path: "/equipment/create",
        name: "EquipmentCreate",
        meta: {},
        component: () => import("@/views/equipment/EquipmentCreate"),
      },
      {
        path: "/equipment/:id/update",
        name: "EquipmentUpdate",
        meta: {},
        component: () => import("@/views/equipment/EquipmentUpdate"),
      },
      {
        path: "/equipment/:id",
        name: "EquipmentShow",
        component: () => import("@/views/equipment/EquipmentShow"),
        meta: {},
        redirect: { name: "EquipmentMain" },
        children: [
          {
            path: "",
            name: "EquipmentMain",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentMain"),
          },
          {
            path: "list",
            name: "EquipmentWaybill",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentWaybill"),
          },
          {
            path: "media",
            name: "EquipmentMedia",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentMedia"),
          },
          {
            path: "defect",
            name: "EquipmentDefect",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentDefect"),
          },
          {
            path: "ticket",
            name: "EquipmentTicket",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentTicket"),
          },
          {
            path: "map",
            name: "EquipmentMap",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentMap"),
          },
          {
            path: "catalog",
            name: "EquipmentCatalog",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentCatalog"),
          },
          {
            path: "expense",
            name: "EquipmentExpense",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentExpense"),
          },
          {
            path: "mileage",
            name: "EquipmentMileage",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentMileage"),
          },
          {
            path: "work-type",
            name: "EquipmentWorkType",
            meta: {},
            component: () => import("@/views/equipment/show/EquipmentWorkType"),
          },
          {
            path: "service-schedule",
            name: "EquipmentServiceSchedule",
            meta: {},
            component: () =>
              import("@/views/equipment/show/EquipmentServiceSchedule"),
          },
        ],
      },
      {
        path: "/equipment-condition",
        name: "EquipmentCondition",
        meta: {},
        component: () => import("@/views/equipment/EquipmentCondition"),
      },
      {
        path: "/equipment-monitoring",
        name: "EquipmentMonitoring",
        meta: {},
        component: () => import("@/views/equipment/EquipmentMonitoring"),
      },
      {
        path: "/med-condition",
        name: "MedCondition",
        meta: {},
        component: () => import("@/views/medical/MedCondition"),
      },

      /* exploitation */
      {
        path: "/exploitation/customer-list",
        name: "CustomerList",
        meta: {},
        component: () => import("@/views/exploitation/CustomerList"),
      },
      {
        path: "/exploitation/orderer-group",
        name: "OrdererGroup",
        meta: {},
        component: () => import("@/views/exploitation/OrdererGroup"),
      },
      {
        path: "/exploitation/shift-schedule",
        name: "ShiftSchedule",
        meta: {},
        component: () => import("@/views/exploitation/ShiftSchedule"),
      },
      {
        path: "/exploitation/route",
        name: "RouteList",
        meta: {},
        component: () => import("@/views/exploitation/RouteList"),
      },
      {
        path: "/exploitation/work-type",
        name: "WorkType",
        meta: {},
        component: () => import("@/views/exploitation/WorkType"),
      },
      {
        path: "/exploitation/order",
        name: "ExploitationOrderList",
        meta: {},
        component: () =>
          import("@/views/exploitation/order/ExploitationOrderList"),
      },
      {
        path: "/exploitation/order/:id",
        name: "ExploitationOrderShow",
        component: () =>
          import("@/views/exploitation/order/ExploitationOrderShow"),
        meta: {},
        redirect: { name: "ExploitationOrderShowMain" },
        children: [
          {
            path: "",
            name: "ExploitationOrderShowMain",
            meta: {},
            component: () => import("@/views/exploitation/order/show/ShowMain"),
          },
          {
            path: "list",
            name: "ExploitationOrderShowJournal",
            meta: {},
            component: () =>
              import("@/views/exploitation/order/show/ShowJournal"),
          },
        ],
      },
      {
        path: "/exploitation/order-mobile",
        name: "ExploitationOrderMobile",
        meta: {},
        component: () =>
          import("@/views/exploitation/order/ExploitationOrderMobile"),
      },
      {
        path: "/exploitation/mileage",
        name: "ExploitationMileageList",
        component: () => import("@/views/exploitation/mileage/MileageList"),
      },
      {
        path: "/exploitation/mileage/create",
        name: "ExploitationMileageCreate",
        component: () => import("@/views/exploitation/mileage/MileageCreate"),
      },
      {
        path: "/exploitation/mileage/:id",
        name: "ExploitationMileageUpdate",
        component: () => import("@/views/exploitation/mileage/MileageUpdate"),
      },

      {
        path: "/ticket",
        name: "TicketList",
        component: () => import("@/views/ticket/TicketList"),
      },
      {
        path: "/ticket/create",
        name: "TicketCreate",
        component: () => import("@/views/ticket/TicketCreate"),
      },
      {
        path: "/ticket/:id",
        name: "TicketShow",
        component: () => import("@/views/ticket/TicketShow"),
      },
      {
        path: "/ticket/:id/update",
        name: "TicketUpdate",
        component: () => import("@/views/ticket/TicketUpdate"),
      },

      {
        path: "/defect",
        name: "DefectList",
        component: () => import("@/views/defect/DefectList"),
      },
      {
        path: "/defect/create",
        name: "DefectCreate",
        component: () => import("@/views/defect/DefectCreate"),
      },
      {
        path: "/defect/:id",
        name: "DefectItem",
        component: () => import("@/views/defect/DefectItem"),
      },
      {
        path: "/defect/:id/update",
        name: "DefectUpdate",
        component: () => import("@/views/defect/DefectUpdate"),
      },

      {
        path: "/expense/journal",
        name: "ExpenseList",
        component: () => import("@/views/expense/ExpenseList"),
      },
      {
        path: "/expense/journal/create",
        name: "ExpenseCreate",
        component: () => import("@/views/expense/ExpenseCreate"),
      },
      {
        path: "/expense/journal/:id/update",
        name: "ExpenseUpdate",
        component: () => import("@/views/expense/ExpenseUpdate"),
      },
      {
        path: "/expense/journal/:id",
        name: "ExpenseShow",
        component: () => import("@/views/expense/ExpenseShow"),
      },
      {
        path: "/expense/type",
        name: "ExpenseTypeList",
        component: () => import("@/views/expense/type/TypeList"),
      },
      {
        path: "/expense/type/create",
        name: "ExpenseTypeCreate",
        component: () => import("@/views/expense/type/TypeCreate"),
      },
      {
        path: "/expense/type/:id/update",
        name: "ExpenseTypeUpdate",
        component: () => import("@/views/expense/type/TypeUpdate"),
      },
      {
        path: "/expense/fines",
        name: "ExpenseFines",
        component: () => import("@/views/expense/ExpenseFines"),
      },
      {
        path: "/expense/maintenance-plan",
        name: "MaintenancePlan",
        component: () => import("@/views/expense/maintenance/MaintenancePlan"),
      },
      {
        path: "/expense/maintenance-plan/:id",
        name: "MaintenancePlanShow",
        component: () =>
          import("@/views/expense/maintenance/MaintenancePlanShow"),
      },
      {
        path: "/expense/maintenance-plan-type",
        name: "MaintenanceTypeList",
        component: () => import("@/views/expense/maintenance/type/TypeList"),
      },
      {
        path: "/expense/maintenance-plan-type/create",
        name: "MaintenanceTypeCreate",
        component: () => import("@/views/expense/maintenance/type/TypeCreate"),
      },
      {
        path: "/expense/maintenance-plan-type/:id",
        name: "MaintenanceTypeUpdate",
        component: () => import("@/views/expense/maintenance/type/TypeUpdate"),
      },

      {
        path: "/plan-work",
        name: "PlanWorkList",
        component: () => import("@/views/plan-work/PlanWorkList"),
      },
      {
        path: "/plan-work/create",
        name: "PlanWorkCreate",
        component: () => import("@/views/plan-work/PlanWorkCreate"),
      },
      {
        path: "/plan-work/:id",
        name: "PlanWorkItem",
        component: () => import("@/views/plan-work/PlanWorkItem"),
      },
      {
        path: "/plan-work/:id/update",
        name: "PlanWorkUpdate",
        component: () => import("@/views/plan-work/PlanWorkUpdate"),
      },
      {
        path: "/plan-work/:id/task",
        name: "PlanWorkItemTask",
        component: () => import("@/views/plan-work/item/ItemTask"),
      },
      {
        path: "/plan-work/:id/equipment",
        name: "PlanWorkItemEquipment",
        component: () => import("@/views/plan-work/item/ItemEquipment"),
      },

      {
        path: "/service-work/",
        name: "ServiceWorkList",
        component: () => import("@/views/service-work/ServiceWorkList"),
      },
      {
        path: "/service-work/create",
        name: "ServiceWorCreate",
        component: () => import("@/views/service-work/ServiceWorkCreate"),
      },
      {
        path: "/service-work/:id",
        name: "ServiceWorkUpdate",
        component: () => import("@/views/service-work/ServiceWorkUpdate"),
      },

      {
        path: "/service-schedule/",
        name: "ServiceScheduleList",
        component: () => import("@/views/service-schedule/ServiceScheduleList"),
      },
      {
        path: "/service-schedule/:id",
        name: "ServiceScheduleItem",
        component: () => import("@/views/service-schedule/ServiceScheduleItem"),
      },

      {
        path: "/repair/",
        name: "RepairList",
        component: () => import("@/views/repair/RepairList"),
      },
      {
        path: "/repair/:id",
        name: "RepairItem",
        component: () => import("@/views/repair/RepairItem"),
      },
    ],
  },

  {
    path: "/account",
    component: () => import("@/layouts/AccountLayout"),
    meta: { auth: true },
    redirect: { name: "AccountProfile" },
    children: [
      {
        path: "profile",
        name: "AccountProfile",
        meta: {},
        component: () => import("@/views/account/Profile"),
      },
      {
        path: "password",
        name: "AccountPassword",
        meta: {},
        component: () => import("@/views/account/Password"),
      },
      {
        path: "workspace",
        name: "AccountWorkspace",
        meta: {},
        component: () => import("@/views/account/Workspace"),
      },
      {
        path: "workspace/create",
        name: "AccountWorkspaceCreate",
        meta: {},
        component: () => import("@/views/account/WorkspaceCreate"),
      },
      {
        path: "configuration",
        name: "AccountConfiguration",
        meta: {},
        component: () => import("@/views/account/Configuration"),
      },
    ],
  },

  {
    path: "/admin",
    name: "AdminLogin",
    meta: {},
    component: () => import("@/views/auth/Login"),
  },

  {
    path: "/auth",
    component: () => import("@/layouts/AuthLayout"),
    meta: { auth: false },
    beforeEnter: ifAuthenticated,
    redirect: { name: "Login" },
    children: [
      {
        path: "login",
        name: "Login",
        meta: {},
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "registration",
        name: "Registration",
        meta: {},
        component: () => import("@/views/auth/Registration"),
      },
      {
        path: "registration/success",
        name: "RegistrationSuccess",
        meta: {},
        component: () => import("@/views/auth/Success"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        meta: {},
        component: () => import("@/views/auth/ForgotPassword"),
      },
      {
        path: "forgot-password/success",
        name: "ForgotPasswordSuccess",
        meta: {},
        component: () => import("@/views/auth/Success"),
      },
      {
        path: "reset-password/:userId",
        name: "ResetPassword",
        meta: {},
        component: () => import("@/views/auth/ResetPassword"),
      },
      {
        path: "activate/:id",
        name: "Activate",
        meta: {},
        component: () => import("@/views/auth/Activate"),
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/page/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const user = store.getters["user/user"],
      workspace = store.getters["workspace/workspace"];
    if (user) {
      if (user.admin) {
        next();
      } else if (
        workspace === null &&
        ![
          "AccountPassword",
          "AccountWorkspace",
          "AccountProfile",
          "AccountWorkspaceCreate",
        ].includes(to.name)
      ) {
        next({ name: "AccountWorkspace" });
      } else {
        next();
      }
    } else {
      store.dispatch("user/logoutUser", {}, { root: true });
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
