import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import restApi from "@/plugins/http";
import constants from "@/common/constants";
import "@/plugins/moment";
import VueMask from "v-mask";
import { Api } from "@/common/api";
import { Geotek } from "@/common/geotek";
import HighchartsVue from "highcharts-vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.config.productionTip = false;
Vue.prototype.$restApi = restApi;
Vue.prototype.$constants = constants;
Vue.prototype.$api = new Api();
Vue.prototype.$geotek = new Geotek();
Vue.use(VueMask);
Vue.use(HighchartsVue, { tagName: "highcharts" });
Vue.use(CoolLightBox);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
