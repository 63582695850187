export default {
  namespaced: true,
  state: {
    menuLeft: false,
  },
  mutations: {
    updateMenuLeft(state, payload) {
      state.menuLeft = payload;
    },
  },
  actions: {
    menuLeft({ commit }, payload) {
      commit("updateMenuLeft", payload);
    },
  },
  getters: {
    menuLeft(state) {
      return state.menuLeft;
    },
  },
};
