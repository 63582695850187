import store from "@/store";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    session: null,
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    deleteToken(state) {
      state.token = null;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setSession(state, payload) {
      state.session = payload;
    },
    setUserName(state, payload) {
      state.user.name = payload;
    },
    deleteUser(state) {
      state.user = null;
    },
  },
  actions: {
    setUserToken({ commit }, payload) {
      commit("setToken", payload);
    },
    setUser({ commit }, payload) {
      commit("setUser", payload);
    },
    setSession({ commit }, payload) {
      commit("setSession", payload);
    },
    setUserName({ commit }, payload) {
      commit("setUserName", payload);
    },
    logoutUser({ commit }) {
      commit("deleteUser");
      commit("deleteToken");
      commit("setSession", null);
      store.dispatch("workspace/workspace", null);
      store.dispatch("workspace/workspaces", []);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    session(state) {
      return state.session;
    },
    userRole(state) {
      return state.user ? state.user.admin : null;
    },
    token(state) {
      return state.token;
    },
  },
};
