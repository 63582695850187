export default {
  namespaced: true,
  state: {
    data: [
      {
        id: 1,
        name: "Легковые автомобили",
        count: 11,
        type: "category",
        children: [
          {
            id: 2,
            name: "Caterpillar",
            type: "brand",
            count: 33,
            children: [
              { id: 5, name: "CAT 320", type: "model", count: 11 },
              { id: 6, name: "CAT 310", type: "model", count: 11 },
              { id: 7, name: "CAT 300", type: "model", count: 11 },
            ],
          },
          { id: 3, name: "Экскаваторы гусеничные", type: "brand" },
          { id: 4, name: "Погрузчик", type: "brand" },
        ],
      },
    ],
  },
  mutations: {
    updateHandbookList(state, payload) {
      state.data = payload;
    },
    addCategory(state, payload) {
      state.data = state.data.concat(payload);
    },
    updateCategory(state, payload) {
      let index = state.data.findIndex((el) => el.id === payload.id);
      if (index !== -1) state.data[index] = payload;
    },
    deleteCategory(state, payload) {
      let index = state.data.findIndex((el) => el.id === payload.id);
      if (index !== -1) state.data = state.data.splice(index, 1);
    },
    addSubCategory(state, payload) {
      let index = state.data.findIndex(
        (el) => el.id === parseInt(payload.parentId)
      );
      if (index !== -1) {
        state.data[index]["children"] = state.data[index]["children"].concat(
          payload.data
        );
      }
    },
  },
  actions: {
    handbookList({ commit }, payload) {
      commit("updateHandbookList", payload);
    },
    addCategory({ commit }, payload) {
      commit("addCategory", payload);
    },
    editCategory({ commit }, payload) {
      commit("updateCategory", payload);
    },
    deleteCategory({ commit }, payload) {
      commit("deleteCategory", payload);
    },
    addSubCategory({ commit }, payload) {
      commit("addSubCategory", payload);
    },
  },
  getters: {
    handbookList(state) {
      return state.data;
    },
  },
};
