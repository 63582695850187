import axios from "axios";
import constants from "@/common/constants";

export class Geotek {
  getToken() {
    return new Promise((resolve, reject) => {
      axios
        .post(`https://s5.geotek.pro/api/Token`, constants.GEOTEK.SETTINGS)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
  getObjects(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://s5.geotek.pro/api/Objects`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObject(token, objectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://s5.geotek.pro/api/Objects/${objectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObjectStatus(token, objectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://s5.geotek.pro/api/ObjectsStatus/${objectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObjectStat(token, objectId, fromTimestamp, tillTimestamp) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `https://s5.geotek.pro/api/ObjectsHistory/Common/`,
          {
            from: fromTimestamp,
            till: tillTimestamp,
            objectID: objectId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObjectHistory(token, objectId, fromTimestamp, tillTimestamp) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `https://s5.geotek.pro/api/ObjectsHistory/`,
          {
            from: fromTimestamp,
            till: tillTimestamp,
            objectID: objectId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObjectReportSafety(token, objectId, fromTimestamp, tillTimestamp) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `https://s5.geotek.pro/api/Reports/Safety/`,
          {
            from: fromTimestamp,
            till: tillTimestamp,
            objectID: objectId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getObjectReportFuel(token, objectId, fromTimestamp, tillTimestamp) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `https://s5.geotek.pro/api/Reports/Fuel/`,
          {
            from: fromTimestamp,
            till: tillTimestamp,
            objectID: objectId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getProfile(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://s5.geotek.pro/api/Profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
