<template>
  <v-app>
    <loading
      :active.sync="loading"
      :can-cancel="parameters.loading.canCancel"
      :color="parameters.loading.color"
      :loader="parameters.loading.loader"
      :width="parameters.loading.width"
      :height="parameters.loading.height"
      :background-color="parameters.loading.backgroundColor"
      :opacity="parameters.loading.opacity"
      :z-index="parameters.loading.zIndex"
    />
    <v-snackbar
      v-model="notificationStatus"
      :timeout="parameters.notification.timeout"
      top
      app
      right
      :class="
        notificationType
          ? '__snackbar snackbar-' + notificationType
          : '__snackbar'
      "
    >
      <template v-slot:default>
        <div class="d-flex align-start">
          <div>
            <template v-if="notificationType === 'error'">
              <v-icon color="errorRed" size="20" class="mr-1">
                $vuetify.icons.alertCircle
              </v-icon>
            </template>
            <template v-else-if="notificationType === 'info'">
              <v-icon color="primaryDefault" size="20" class="mr-1">
                $vuetify.icons.alertCircle
              </v-icon>
            </template>
            <template v-else-if="notificationType === 'success'">
              <v-icon color="successGreen" size="20" class="mr-1">
                $vuetify.icons.checkCircle
              </v-icon>
            </template>
          </div>
          <div class="px-3">
            <div class="txt c-greyscale fs-16 lh-24 ff-m mb-1">
              <template v-if="notificationType === 'error'">Ошибка</template>
              <template v-else-if="notificationType === 'info'">
                Информация
              </template>
              <template v-else-if="notificationType === 'success'">
                Успешно
              </template>
            </div>
            <div
              v-html="notificationText"
              class="txt c-greyscale fs-16 lh-18"
            ></div>
          </div>
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primaryDefault"
          icon
          v-bind="attrs"
          @click="notificationStatus = false"
          small
        >
          <v-icon size="16">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/styles/app";
@import "@/assets/styles/media";
</style>

<style>
@import "./assets/styles/materialdesignicons.min.css";
@import "./assets/styles/fonts.css";
</style>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import global from "@/common/mixins/global";

Vue.mixin(global);

export default {
  name: "App",
  components: {
    Loading,
  },
  data: () => ({
    parameters: {
      loading: {
        canCancel: false,
        color: "#e7e7e7",
        loader: "dots",
        width: 128,
        height: 128,
        backgroundColor: "#000",
        opacity: 0.7,
        zIndex: 10000,
      },
      notification: {
        timeout: 7000,
      },
    },
  }),
  mounted() {
    this.$store.dispatch("loading/hide");
  },
  computed: {
    loading() {
      return this.$store.getters["loading/status"];
    },
    notificationText() {
      return this.$store.getters["notification/text"];
    },
    notificationType() {
      return this.$store.getters["notification/type"];
    },
    notificationStatus: {
      get() {
        return this.$store.getters["notification/status"];
      },
      set() {
        this.$store.dispatch("notification/hide");
      },
    },
  },
};
</script>
