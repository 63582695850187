export default {
  namespaced: true,
  state: {
    workspace: null,
    workspaces: [],
  },
  mutations: {
    updateWorkspace(state, payload) {
      state.workspace = payload;
    },
    updateWorkspaces(state, payload) {
      state.workspaces = payload;
    },
  },
  actions: {
    workspace({ commit, rootState }, payload) {
      commit("updateWorkspace", payload);
      if (payload) {
        let user = rootState.user.user;
        user["member_id"] = user.member[payload.id];
        commit("user/setUser", user, { root: true });
      }
    },
    workspaces({ commit }, payload) {
      commit("updateWorkspaces", payload);
    },
  },
  getters: {
    workspace(state) {
      return state.workspace;
    },
    workspaces(state) {
      return state.workspaces;
    },
  },
};
