import axios from "axios";
import store from "@/store";
import router from "@/router";
import constants from "@/common/constants";

const restApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

restApi.interceptors.request.use(
  (config) => {
    const token = store.getters["user/token"],
      workspace = store.getters["workspace/workspace"];
    if (token) {
      config.headers["Authorization"] = token.access;
    }
    if (workspace) config.headers["Workspace"] = workspace.id;
    return config;
  },
  (error) => Promise.reject(error)
);

restApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const token = store.getters["user/token"];
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 400) {
        if (error.response && "message" in error.response.data) {
          let message = error.response.data.message;
          if (
            message === "hashedPassword is not the hash of the given password"
          ) {
            message = "Неверный пароль";
          }
          await store.dispatch("notification/show", {
            text: message,
            type: "error",
          });
        }
      } else if (error.response.status === 401) {
        if (!originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const response = await restApi.post(
              `/api/v1/workspace/auth/authenticate`,
              {},
              {
                params: {
                  grant_type: "refresh_token",
                  refresh_token: token.refresh,
                },
              }
            );
            await store.dispatch("user/setUserToken", {
              access: response.data["access_token"],
              refresh: response.data["refresh_token"],
              type: response.data["token_type"],
            });
            return restApi(originalConfig);
          } catch (_error) {
            store
              .dispatch("user/logoutUser", {}, { root: true })
              .then(() => router.push({ name: "Login" }));
            return Promise.reject(_error);
          }
        } else if (
          "code" in error.response.data &&
          error.response.data.code === "token_not_valid"
        ) {
          store
            .dispatch("user/logoutUser", {}, { root: true })
            .then(() => router.push({ name: "Login" }));
        } else {
          if (error.response && "message" in error.response.data) {
            await store.dispatch("notification/show", {
              text: error.response.data.message,
              type: "error",
            });
          }
        }
      } else if (error.response.status === 403) {
        await store.dispatch("notification/show", {
          text: constants.MESSAGE.ERROR.FORBIDDEN,
          type: "error",
        });
      } else if (error.response.status === 404) {
        await store.dispatch("notification/show", {
          text: constants.MESSAGE.ERROR.NOT_FOUND,
          type: "error",
        });
      } else if ([500, 502, 503, 504].includes(error.response.status)) {
        await store.dispatch("notification/show", {
          text: constants.MESSAGE.ERROR.SERVER,
          type: "error",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default restApi;
